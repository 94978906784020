// SkillUpdateModal.jsx

import { faBan, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * SkillUpdateModal allows the user to update a skill
 * @param {{
 *      nameSkillRef: React.RefObject<HTMLInputElement>;
 *      descriptionSkillRef: React.RefObject<HTMLTextAreaElement>;
 *      skillInfoObj: {
 *          name: string;
 *          description: string;
 *      };
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function SkillUpdateModal({ 
    nameSkillRef,
    descriptionSkillRef,
    skillInfoObj,
    onAccept, 
    onCancel 
}) { 

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const validate = () => {

        const validationErrors = [];

        if (!nameSkillRef.current.value) {
            validationErrors.push(i18next.t('skill-name'));
        }

        if (nameSkillRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        if (descriptionSkillRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-description'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);

            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept();
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
            <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('skill-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('skill-name')}</Title>
                <Input
                    inputRef={nameSkillRef}
                    color="gray"
                    type="text"
                    defaultValue={skillInfoObj.name}
                    placeholder={i18next.t('skill-ex-name')}
                    focus
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('skill-description')}</Title>
                <Input
                    inputRef={descriptionSkillRef}
                    color="gray"
                    type="textarea"
                    defaultValue={skillInfoObj.description}
                    placeholder={i18next.t('skill-ex-description')}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

SkillUpdateModal.defaultProps = {
    descriptionSkillRef: null,
};

SkillUpdateModal.propTypes = {
    nameSkillRef: PropTypes.shape().isRequired,
    descriptionSkillRef: PropTypes.shape(),
    skillInfoObj: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SkillUpdateModal;