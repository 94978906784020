import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import DOMPurify from 'dompurify';
import 'quill/dist/quill.snow.css';

function QuillEditor({ initialValue = '', onChange }) {
  const editorRef = useRef(null);
  const quillInstance = useRef(null);

  useEffect(() => {
    if (!quillInstance.current) {
      quillInstance.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ size: [ 'small', false, 'large', 'huge' ]}],
            [{ 'color': [] }], 
            ['code-block'],
          ]
        },
        placeholder: 'Escribe aquí...',
        scrollingContainer: editorRef.current,
      });

      const sanitizedInitialValue = DOMPurify.sanitize(initialValue);
      quillInstance.current.clipboard.dangerouslyPasteHTML(sanitizedInitialValue);

      quillInstance.current.on('text-change', () => {
        const html = quillInstance.current.root.innerHTML;
        const sanitizedHtml = DOMPurify.sanitize(html);
        if (onChange) {
          onChange(sanitizedHtml);
        }
      });
    }

    return () => {
      if (quillInstance.current) {
        quillInstance.current.off('text-change');
        quillInstance.current = null;
      }
    };
  }, [initialValue, onChange]);

  return (
    <>
      <style>
        {`
          .ql-container {
            height: auto !important;
            min-height: 300px;
            max-height: 600px;
            overflow-y: auto;
          }
        `}
      </style>
      <div ref={editorRef} style={{ height: '300px', minHeight: '300px' }} />
    </>
  );
}

QuillEditor.defaultProps = {
  initialValue: ''
};

QuillEditor.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default QuillEditor;