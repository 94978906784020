// ChallengeCreateModal.jsx

import { faBan, faPlus, faPaperPlane, faMinus, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Select from '../atoms/Select';
import QuillEditor from '../molecules/QuillEditor';
import SelectMultiple from '../atoms/SelectMultiple';

/**
 * ChallengeCreateModal allows the user to create a new Challenge
 * @param {{
 *      nameChaRef: React.RefObject<HTMLInputElement>;
 *      chaTypeRef: React.RefObject<HTMLSelectElement>;
 *      statusChaRef: React.RefObject<HTMLSelectElement>;
 *      visibilityChaRef: React.RefObject<HTMLSelectElement>;
 *      descriptionChaRef: React.RefObject<string>;
 *      clarificationsChaRef: React.RefObject<string>;
 *      maximumRef: React.RefObject<HTMLInputElement>;
 *      scoreTypeRef: React.RefObject<HTMLSelectElement>;
 *      scoreRef: React.RefObject<HTMLInputElement>;
 *      chaCatRef: React.RefObject<HTMLSelectElement>;
 *      categories: object;
 *      skills: Array<{ id: number; name: string }>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function ChallengeCreateModal({
    nameChaRef,
    chaTypeRef,
    statusChaRef,
    visibilityChaRef,
    descriptionChaRef,
    clarificationsChaRef,
    maximumRef,
    chaCatRef,
    scoreTypeRef,
    scoreRef,
    categories,
    skills,
    onAccept,
    onCancel
}) {

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const [selectedChaType, setSelectedChaType] = useState('PLAIN');
    const [selectedStatus, setSelectedStatus] = useState('PRODUCTION');
    const [selectedVisibility, setSelectedVisibility] = useState('PUBLIC');
    const [selectedScoreType, setSelectedScoreType] = useState('VARIABLE');
    const [selectedCategory, setSelectedCategory] = useState("20");
    const [answerInputs, setAnswerInputs] = useState([{ id: 1, data: '', valid: true }]);
    const [selectedSkills, setSelectedSkills] = useState([]);


    const categoryObject = {
        categories: categories.categories.reduce((acc, category) => {
            acc[category.id] = category.name;
            return acc;
        }, {})
    };

    const skillsObject = {
        skills: skills.reduce((acc, skill) => {
          acc[skill.id] = skill.name;
          return acc;
        }, {})
    };
    
    const toggleAnswerValidity = (id) => {
        setAnswerInputs(prevInputs =>
            prevInputs.map(input => {
                if (input.id === id) {
                    return {
                        ...input,
                        valid: !input.valid || false
                    };
                }
                return input;
            })
        );
    };

    const addAnswerInput = () => {
        if (answerInputs.length < 10) {
            setAnswerInputs(prevInputs => [...prevInputs, { id: prevInputs.length + 1, data: '', valid: false }]);
        }
    };

    const removeAnswerInput = () => {
        if (answerInputs.length > 1) {
            setAnswerInputs(prevInputs => prevInputs.slice(0, -1));
        }
    };


    // Handler for multiple answers (PLAIN and CODE Challenges)
    const handleAnswersChange = (id, data) => {
        setAnswerInputs(prevInputs =>
            prevInputs.map(input => {
                if (input.id === id) {
                    return { ...input, data };
                }
                return input;
            })
        );
    };

    // Handler for single answer (REGEXP and OPEN Challenges)
    const handleAnswerChange = (newValue) => {
        setAnswerInputs([{ id: 1, data: newValue, valid: true }]);
    };

    // Handler for description
    const handleUpdateDescription = useCallback((newDescription) => {
        if (descriptionChaRef.current !== null) {
            // eslint-disable-next-line no-param-reassign
            descriptionChaRef.current = newDescription
        }
    }, [descriptionChaRef]);

    // Handler for clarifications
    const handleUpdateClarification = useCallback((newClarification) => {
        if (clarificationsChaRef.current !== null) {
            // eslint-disable-next-line no-param-reassign
            clarificationsChaRef.current = newClarification
        }
    }, [clarificationsChaRef]);

    // Challenge Type
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        chaTypeRef.current = selectedChaType;

        // The state of answerInputs is reset whenever the chaType changes
        setAnswerInputs([{ id: 1, data: '', valid: true }]);
    }, [selectedChaType, chaTypeRef]);


    // Status
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        statusChaRef.current = selectedStatus;
    }, [selectedStatus, statusChaRef]);

    // Visibility
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        visibilityChaRef.current = selectedVisibility;
    }, [selectedVisibility, visibilityChaRef]);

    // Score Type
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        scoreTypeRef.current = selectedScoreType;
    }, [selectedScoreType, scoreTypeRef]);

    // Category
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        chaCatRef.current = selectedCategory;
    }, [selectedCategory, chaCatRef]);


    useEffect(() => {
        setAnswerInputs(prevInputs =>
            prevInputs.map(input => ({
                ...input,
                valid: (selectedChaType === 'PLAIN' || selectedChaType === 'CODE') ? input.valid : true,
                validEditable: (selectedChaType === 'PLAIN' || selectedChaType === 'CODE')
            }))
        );
    }, [selectedChaType]);

    // PLAIN & CODE Challenge Logic
    const renderPlainCodeInputs = () => (
        <div className="w-full flex flex-col gap-2">
            <div className="flex items-center gap-x-7 mb-2">
                <Title size="sm">{i18next.t('answers')}</Title>
                <div className="w-1/12">
                    {answerInputs.length < 10 && (
                        <Button color="green" rounded icon={faPlus} onClick={addAnswerInput} title={i18next.t('answer-add')} />
                    )}
                </div>
                <div className="w-1/12">
                    {answerInputs.length > 1 && (
                        <Button color="red" rounded icon={faMinus} onClick={removeAnswerInput} title={i18next.t('answer-remove')} />
                    )}
                </div>
            </div>
            {selectedChaType === 'CODE' && (
                <div className="w-full text-center bg-orange-300 text-orange-800 p-2">
                    <Title color="orange" size="md">
                        {i18next.t('code-answer-note')}
                    </Title>
                </div>
            )}
            {answerInputs.map(input => (
                <div key={input.id} className="flex w-full gap-3 items-center">
                    
                    <div className="w-1/12 text-center">
                        <Title size="lg">{`${input.id}`}</Title>
                    </div>
                    <div className="w-10/12">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        <Input value={selectedChaType === 'CODE' ? (input.data === '' ? '{"input":[""],"output":[""]}' : input.data) : input.data}
                            onType={newValue => handleAnswersChange(input.id, newValue)}
                            color="gray"
                            type="text"
                        />
                    </div>
                    <div className="w-1/12">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="flex justify-center items-center gap-1">
                            <input
                                type="checkbox"
                                checked={input.valid}
                                onChange={() => toggleAnswerValidity(input.id)}
                                className="h-8 w-8"
                            />
                        </label>
                    </div>
                </div>
            ))}
        </div>
    );
    
    // REGEXP Challenge Logic
    const renderRegexpInput = () => (
        <div className="w-full flex flex-col gap-2">
            <Title size="sm">{i18next.t('answer')}</Title>
            <Input
                value={answerInputs[0].data}
                onType={newValue => handleAnswerChange(newValue)}
                color="gray"
                type="text"

            />
        </div>
    );
    
    // OPEN Challenge Logic
    const renderOpenInput = () => (
        <div className="w-fit flex flex-col p-2 border-2 border-smyellow-dark">
            <Title size="md">{i18next.t('open-answer-note')}</Title>
        </div>
    );
    
    const renderInputs = () => {
        switch (selectedChaType) {
            case 'PLAIN':
                return renderPlainCodeInputs();
            case 'REGEXP':
                return renderRegexpInput();
            case 'OPEN':
                return renderOpenInput();
            case 'CODE':
                return renderPlainCodeInputs();
            default:
                return null;
        }
    };

    const validate = () => {

        const validationErrors = [];

        if (!nameChaRef.current.value) {
            validationErrors.push(i18next.t('name'));
        }

        if (!chaTypeRef.current) {
            validationErrors.push(i18next.t('type'));
        }

        if (!statusChaRef.current) {
            validationErrors.push(i18next.t('status'));
        }

        if (!visibilityChaRef.current) {
            validationErrors.push(i18next.t('visibility'));
        }
        
        if (!descriptionChaRef.current) {
            validationErrors.push(i18next.t('description'));
        }

        if (!maximumRef.current.value) {
            validationErrors.push(i18next.t('max-attempts'));
        }

        if (!scoreTypeRef.current) {
            validationErrors.push(i18next.t('score-type'));
        }

        if (!scoreRef.current.value) {
            validationErrors.push(i18next.t('score'));
        }

        if (nameChaRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        const scoreValue = parseFloat(scoreRef.current.value);

        if (isNaN(scoreValue) || scoreValue < 0 || scoreValue > 100) {
            createFeedback('warn', i18next.t('invalid-score-range'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);
            return false;
        }
        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept(answerInputs, selectedSkills );
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
                <FontAwesomeIcon icon={faPlus} size="xl" className="text-smgreen-dark" />
                <Title size="lg">{i18next.t('challenge-create')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-row gap-2">
                <div className="w-7/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('name')}</Title>
                    <Input
                        focus
                        inputRef={nameChaRef}
                        color="gray"
                        type="text"
                    />
                </div>
                <div className="w-3/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('category')}</Title>
                    <Select
                        color='gray'
                        isLoading={false}
                        selected={selectedCategory || "20"} 
                        onSelect={(selected) => { setSelectedCategory(selected); }}
                        options={categoryObject}
                        enableSearch
                    />
                </div>
                <div className="w-2/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('type')}</Title>
                    <Select
                        color="gray"
                        selected={selectedChaType || 'PLAIN'}
                        onSelect={(selected) => { setSelectedChaType(selected); }}
                        options={{
                            [null]: {
                                HASH: 'HASH',
                                REGEXP: 'REGEXP',
                                DYNAMIC: 'DYNAMIC',
                                PLAIN: 'PLAIN',
                                CODE: 'CODE',
                                OPEN: 'OPEN',
                            },
                        }}
                    />
                </div>
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('skills')}</Title>
                <SelectMultiple
                    color='gray'
                    selected={selectedSkills}
                    onSelect={(selected) => setSelectedSkills(selected)}
                    options={skillsObject}
                />
            </div>
            <div className="w-full flex flex-row gap-2">
                <div className="w-3/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('status')}</Title>
                    <Select
                        color='gray'
                        selected={selectedStatus || 'PRODUCTION'}
                        // eslint-disable-next-line no-param-reassign
                        onSelect={(selected) => { setSelectedStatus(selected); }}
                        options={{
                            [null]: {
                                QUEUE: i18next.t('queued'),
                                NOT_APPROVED: i18next.t('not-approved'),
                                STAGING: i18next.t('staging'),
                                DISABLED: i18next.t('disabled'),
                                DELETED: i18next.t('deleted'),
                                PRODUCTION: i18next.t('production'),
                            },
                        }}
                    />
                </div>
                <div className="w-2/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('visibility')}</Title>
                    <Select
                        color='gray'
                        selected={selectedVisibility || 'PUBLIC'}
                        // eslint-disable-next-line no-param-reassign
                        onSelect={(selected) => { setSelectedVisibility(selected); }}
                        options={{
                            [null]: {
                                PUBLIC: i18next.t('pub'),
                                /* PRIVATE: i18next.t('priv'), deshabilitado hasta ser necesario */
                            },
                        }}
                    />
                </div>
                <div className="w-3/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('attempts')}</Title>
                    <Input
                        inputRef={maximumRef}
                        color="gray"
                        type="number"
                        defaultValue='1'
                        readOnly
                    />
                </div>
                <div className="w-2/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('score-type')}</Title>
                    <Select
                        color='gray'
                        selected={selectedScoreType || 'VARIABLE'}
                        // eslint-disable-next-line no-param-reassign
                        onSelect={(selected) => { setSelectedScoreType(selected); }}
                        options={{
                            [null]: {
                                VARIABLE: i18next.t('variable'),
                                FIXED: i18next.t('fixed'),
                            },
                        }}
                    />
                </div>
                <div className="w-2/12 flex flex-col gap-2">
                    <Title size="sm">{i18next.t('score')}</Title>
                    <Input
                        inputRef={scoreRef}
                        color="gray"
                        type="number"
                        defaultValue='50'
                    />
                </div>
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('description')}</Title>
                <QuillEditor initialValue='' onChange={handleUpdateDescription} />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('clarifications')}</Title>
                <QuillEditor initialValue='' onChange={handleUpdateClarification} />
            </div>
            {/* Answers Logic */}
            {renderInputs()}
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="green" icon={faPaperPlane} onClick={handleAccept}>
                    {i18next.t('save')}
                </Button>
            </div>
            <div className="fixed top-0 right-0 m-4 items-center" title={i18next.t('close')}>
                <Button color="red" icon={faClose} onClick={onCancel}>
                    {i18next.t('close')}
                </Button>
            </div>
        </div>
    );
}

ChallengeCreateModal.defaultProps = {
    clarificationsChaRef: null,
  };

ChallengeCreateModal.propTypes = {
    nameChaRef: PropTypes.shape().isRequired,
    chaTypeRef: PropTypes.shape().isRequired,
    statusChaRef: PropTypes.shape().isRequired,
    visibilityChaRef: PropTypes.shape().isRequired,
    descriptionChaRef: PropTypes.shape().isRequired,
    clarificationsChaRef: PropTypes.shape(),
    maximumRef: PropTypes.shape().isRequired,
    scoreTypeRef: PropTypes.shape().isRequired,
    scoreRef: PropTypes.shape().isRequired,
    chaCatRef: PropTypes.shape().isRequired,
    categories: PropTypes.shape().isRequired,
    skills: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ChallengeCreateModal;