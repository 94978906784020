import { faPenToSquare, faTrash, faUsers, faList, faLayerGroup, faRankingStar, faPlus, faArrowsRotate, faCircle, faLock, faUnlock, faEye, faUserCheck, faUserLock, faInfinity, faBan, faUsersGear, faGears } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import { useState, useEffect, useRef  } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import useModal from '../../hooks/useModal';
import getCategories from '../../services/getCategories';
import getCountries from '../../services/getCountries';
import getAdminCategories from '../../services/getAdminCategories';
import deleteAdminCategory from '../../services/deleteAdminCategory';
import updateAdminCategory from '../../services/updateAdminCategory';
import createAdminCategory from '../../services/createAdminCategory';
import getAdminSkills from '../../services/getAdminSkills';
import createAdminSkill from '../../services/createAdminSkill';
import updateAdminSkill from '../../services/updateAdminSkill';
import getCompetitions from '../../services/getCompetitions';
import deleteAdminCompetition from '../../services/deleteAdminCompetition';
import viewAdminCompetition from '../../services/viewAdminCompetition';
import getAdminChallenges from '../../services/getAdminChallenges';
import createAdminChallenge from '../../services/createAdminChallenge';
import deleteAdminChallenge from '../../services/deleteAdminChallenge';
import updateAdminChallenge from '../../services/updateAdminChallenge';
import getAdminUsers from '../../services/getAdminUsers';
import deleteAdminUser from '../../services/deleteAdminUser';
import updateAdminUser from '../../services/updateAdminUser';
import createAdminUser from '../../services/createAdminUser';
import SkeletonModal from '../modals/SkeletonModal';
import BanModal from '../modals/BanModal';
import CategoryCreateModal from '../modals/CategoryCreateModal';
import CategoryDeleteModal from '../modals/CategoryDeleteModal';
import CategoryUpdateModal from '../modals/CategoryUpdateModal';
import SkillCreateModal from '../modals/SkillCreateModal';
import SkillUpdateModal from '../modals/SkillUpdateModal';
import ChallengeCreateModal from '../modals/ChallengeCreateModal';
import ChallengeDeleteModal from '../modals/ChallengeDeleteModal';
import ChallengeUpdateModal from '../modals/ChallengeUpdateModal';
import ChallengeViewModal from '../modals/ChallengeViewModal';
import CompetitionDeleteModal from '../modals/CompetitionDeleteModal';
import CompetitionViewModal from '../modals/CompetitionViewModal';
import UserCreateModal from '../modals/UserCreateModal';
import UserUpdateModal from '../modals/UserUpdateModal';
import UserViewModal from '../modals/UserViewModal';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime';
import datetimeToUTC from '../../utils/datetimeToUTC';
import Link from '../atoms/Link';
import Button from '../atoms/Button';
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';
import NavbarTemplate from '../templates/NavbarTemplate';
import Container from '../atoms/Container';
import Repeat from '../atoms/Repeat';
import SimpleSearch from '../molecules/SimpleSearch';
import Pager from '../molecules/Pager';
import UserStatusModal from '../modals/UserStatusModal';
import activateAdminUser from '../../services/activateAdminUser';
import getUser from '../../services/getUser';
import banAdminUser from '../../services/banAdminUser';
import BanDetailModal from '../modals/BanDetailModal';
import Feedback from '../atoms/Feedback';
import ChallengeAdminSearch from '../organisms/ChallengeAdminSearch';
import CompetitionAdminSearch from '../organisms/CompetitionAdminSearch';
import UserAdminSearch from '../organisms/UserAdminSearch';
import getAllSkills from '../../services/getAllSkills';
import ControlPanelDomain from '../organisms/ControlPanelDomain';

function ControlPanel() {

    const { session } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    // Category Refs
    const nameRef = useRef();
    const descriptionRef = useRef();
    // Skill Refs
    const nameSkillRef = useRef();
    const descriptionSkillRef = useRef();
    // User Refs
    const emailRef = useRef();
    const passwordRef = useRef();
    const countryRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const sexRef = useRef();
    const birthdayRef = useRef();
    const webRef = useRef();
    const biographyRef = useRef();
    const phoneRef = useRef();
    const twitterRef = useRef();
    const facebookRef = useRef();
    const linkedinRef = useRef();
    const githubRef = useRef();
    // User [Ban] - Refs
    const banReasonRef = useRef();
    const banEndRef = useRef();
    const banCommRef = useRef();
    // Challenge Refs
    const nameChaRef = useRef();
    const chaTypeRef = useRef();
    const statusChaRef = useRef();
    const visibilityChaRef = useRef();
    const descriptionChaRef = useRef('');
    const clarificationsChaRef = useRef('');
    const maximumRef = useRef();
    const chaCatRef = useRef();
    const scoreRef= useRef();
    const scoreTypeRef = useRef();


    const [indexPage, setIndexPage] = useState(0);

    const [categoryPage, setCategoryPage] = useState(0);
    const [competitionPage, setCompetitionPage] = useState(0);
    const [userPage, setUserPage] = useState(0);
    const [challengePage, setChallengePage] = useState(0);
    const [skillPage, setSkillPage] = useState(0);
    
    const perPage  = 10;

    // Category Logic
    const [categories, setCategories] = useState([]);
    const [searchCategoryTerm, setSearchCategoryTerm] = useState('');
    const [errorCategoriesLoading, setErrorCategoriesLoading] = useState(false);

    // Get user
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
    });

    // Redirect if user is not an admin
    useEffect(() => {
        if (userQuery.isSuccess && userQuery.data &&!userQuery.data?.roles.includes('admin')) {
            navigate('/');
        }
    }, [userQuery.isSuccess, userQuery.data, navigate]);

    // Get countries
    const countriesQuery = useQuery({
        queryKey: [session, 'countries'],
        queryFn: () => getCountries(session, i18next.language),
        enabled: indexPage === 3 && !!session,
    });

    // Get all the Skills for the Challenge and Skill panels
    const allSkillsQuery = useQuery({
        queryKey: [session, 'skills'],
            queryFn: () => getAllSkills(session, i18next.language),
            enabled: (indexPage === 2 || indexPage === 4) && !!session,
            retry: (failureCount, error) => {
                if (error.response) {
                    if (error.response.status >= 400 && error.response.status < 500) {
                        return false;
                    }
                }
                // Retry for server-side errors (5xx) or network issues
                return failureCount < 3;
            },
            retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
            refetchOnWindowFocus: false,
            staleTime: 2 * 60 * 1000, 
            cacheTime: 5 * 60 * 1000,
    });

    // Reset category page when filters change
    useEffect(() => {
        setCategoryPage(0);
    }, [searchCategoryTerm]);

    const categoriesQuery = useQuery({
        queryKey: [session, 'admin', 'categories', categoryPage, perPage, searchCategoryTerm],
        queryFn: () => getAdminCategories(categoryPage, perPage, session, i18next.language, searchCategoryTerm),
        enabled: indexPage === 0 && !!session,
        onError: () => {
            createFeedback("error", i18next.t('error-loading-categories'));
            setErrorCategoriesLoading(true);
        },
        onSuccess: () => {
            setErrorCategoriesLoading(false);
        },
    });

    const handleCategoriesReload = () => {
        setErrorCategoriesLoading(false);
        categoriesQuery.refetch();
    };

    const handleCategorySearch = (name) => {
        setSearchCategoryTerm(name);
    };

    useEffect(() => {
        if (categoriesQuery.isSuccess && categoriesQuery.data) {
            setCategories(categoriesQuery.data.categories);
        }
    }, [categoriesQuery.isSuccess, categoriesQuery.data]);

    const categoryCreateMutation = useMutation({
        mutationFn: (cat) =>
            createAdminCategory(
                cat.name,
                cat.description,
                session,
                i18next.language
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: async () => {
            createFeedback('success', i18next.t('category-created-successfully'));


            const queryKeyCategories = [session, 'admin', 'categories', categoryPage, perPage, searchCategoryTerm];
            queryClient.invalidateQueries(queryKeyCategories);

            
            const totalPages = categoriesQuery.data?.pages || 1;

            const totalElements = categoriesQuery.data?.categories.length || 1;

            (totalElements % perPage === 0) ? setCategoryPage(totalPages) : setCategoryPage(totalPages - 1);
        }
      });

    /**
     * Create a new category
     */
    const handleCategoryCreate = () => {

        const onAccept = () => {
            
            if (!nameRef.current.value) {
                return;
            }

            const trimmedName = nameRef.current.value.trim();
            const trimmedDescription = descriptionRef?.current?.value.trim() || null;

            categoryCreateMutation.mutate({
                name: trimmedName,
                description: trimmedDescription
            });

            removeModal();
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <CategoryCreateModal
                nameRef={nameRef}
                descriptionRef={descriptionRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />, true
        );
    };

    const categoryUpdateMutation = useMutation({
        mutationFn: (cat) => updateAdminCategory(cat.catID, cat.name, cat.description, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('category-updated-successfully'));

            const queryKeyCategories = [session, 'admin', 'categories', categoryPage, perPage, searchCategoryTerm];
            queryClient.invalidateQueries(queryKeyCategories);
        }
    });

    /**
     * Update Category
     */
    const handleCategoryUpdate = (categoryInfoObj) => {
        const onAccept = () => {
            if (!nameRef.current.value) {    
                return;
            }
            
            const trimmedName = nameRef.current.value.trim();
            const trimmedDescription = descriptionRef?.current?.value.trim() || null;

            categoryUpdateMutation.mutate({
                catID: categoryInfoObj.id?.toString(),
                name: trimmedName,
                description: trimmedDescription,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CategoryUpdateModal
                nameRef={nameRef}
                descriptionRef={descriptionRef}
                onAccept={onAccept}
                onCancel={onCancel}
                categoryInfoObj={{
                    catID: categoryInfoObj && categoryInfoObj.id ? categoryInfoObj.id?.toString() : '',
                    name: categoryInfoObj && categoryInfoObj.name ? categoryInfoObj.name : '',
                    description: categoryInfoObj && categoryInfoObj.description ? categoryInfoObj.description : '',
                }}
            />, true
        );
    };

    const categoryDeleteMutation = useMutation({
        mutationFn: (categoryID) => deleteAdminCategory(categoryID, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('category-deleted-successfully'));

            const queryKeyCategories = [session, 'admin', 'categories', categoryPage, perPage];
            queryClient.invalidateQueries(queryKeyCategories);

            const totalPages = categoriesQuery.data?.pages || 1;
            const totalElements = categoriesQuery.data?.categories.length || 1;

            (totalElements - 1  === 0) ? setCategoryPage(totalPages - 2) : setCategoryPage(totalPages - 1);
        }
    });
    
    /**
     * Delete Category
     */
    const handleCategoryDelete = (catInfo) => {
        // Delete a Category
        const onAccept = () => {
            removeModal();
            if (!catInfo || !catInfo.catID) return;
            
            categoryDeleteMutation.mutate(catInfo.catID);
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <CategoryDeleteModal
                name={catInfo.name}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };

    // Skill Logic

    const [skills, setSkills] = useState([]);
    const [searchSkillTerm, setSearchSkillTerm] = useState('');
    const [errorSkillsLoading, setErrorSkillsLoading] = useState(false);

    // Reset skill page when filters change
    useEffect(() => {
        setSkillPage(0);
    }, [searchSkillTerm]);

    const queryKeySkills  = [session, 'admin', 'skill', skillPage, perPage, searchSkillTerm];

    const skillsQuery = useQuery({
        queryKey: queryKeySkills,
        queryFn: () => getAdminSkills(skillPage, perPage, session, i18next.language, searchSkillTerm),
        enabled: indexPage === 4 && !!session,
        onError: () => {
            createFeedback("error", i18next.t('error-loading-skills'));
            setErrorSkillsLoading(true);
        },
        onSuccess: () => {
            setErrorSkillsLoading(false);
        },
    });

    const handleSkillsReload = () => {
        setErrorSkillsLoading(false);
        skillsQuery.refetch();
    };

    const handleSkillSearch = (name) => {
        setSearchSkillTerm(name);
    };

    useEffect(() => {
        if (skillsQuery.isSuccess && skillsQuery.data) {
            setSkills(skillsQuery.data.skills);
        }
    }, [skillsQuery.isSuccess, skillsQuery.data]);

    const skillCreateMutation = useMutation({
        mutationFn: (skill) =>
            createAdminSkill(
                skill.name,
                skill.description,
                session,
                i18next.language
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: async () => {
            createFeedback('success', i18next.t('skill-created-successfully'));
            queryClient.invalidateQueries(queryKeySkills);

            
            const totalPages = skillsQuery.data?.pages || 1;

            const totalElements = skillsQuery.data?.skills.length || 1;

            (totalElements % perPage === 0) ? setSkillPage(totalPages) : setSkillPage(totalPages - 1);
        }
      });

    /**
     * Create a new skill
     */
    const handleSkillCreate = () => {

        const onAccept = () => {
            
            if (!nameSkillRef.current.value) {
                return;
            }

            const trimmedName = nameSkillRef.current.value.trim();
            const trimmedDescription = descriptionSkillRef?.current?.value.trim() || null;

            skillCreateMutation.mutate({
                name: trimmedName,
                description: trimmedDescription
            });

            removeModal();
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <SkillCreateModal
                nameSkillRef={nameSkillRef}
                descriptionSkillRef={descriptionSkillRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />, true
        );
    };

    const skillUpdateMutation = useMutation({
        mutationFn: (skill) => 
            updateAdminSkill(skill.skillID, skill.name, skill.description, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('skill-updated-successfully'));
            queryClient.invalidateQueries(queryKeySkills);
        }
    });

    /**
     * Update Skill
     */
    const handleSkillUpdate = (skillInfoObj) => {
        const onAccept = () => {
            if (!nameSkillRef.current.value) {    
                return;
            }

            const trimmedName = nameSkillRef.current.value.trim();
            const trimmedDescription = descriptionSkillRef?.current?.value.trim() || null;

            skillUpdateMutation.mutate({
                skillID: skillInfoObj.id?.toString(),
                name: trimmedName,
                description: trimmedDescription
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <SkillUpdateModal
                nameSkillRef={nameSkillRef}
                descriptionSkillRef={descriptionSkillRef}
                onAccept={onAccept}
                onCancel={onCancel}
                skillInfoObj={{
                    skillID: skillInfoObj && skillInfoObj.id ? skillInfoObj.id?.toString() : '',
                    name: skillInfoObj && skillInfoObj.name ? skillInfoObj.name : '',
                    description: skillInfoObj && skillInfoObj.description ? skillInfoObj.description : '',
                }}
            />, true
        );
    };

    // Competition Logic

    const [errorCompetitionsLoading, setErrorCompetitionsLoading] = useState(false);

    const [competitions, setCompetitions] = useState([]);
    const [criteria, setCriteria] = useState({ name: '', status: [], categories: [] });
    const [competitionFeedbackMessage, setCompetitionFeedbackMessage] = useState(null);


    // Get categories
    const categoryListQuery = useQuery({
        queryKey: [session, 'categories'],
        queryFn: () => getCategories(i18next.language),
        enabled: indexPage === 2 && !!session,
        retry: (failureCount, error) => {
            if (error.response) {
                if (error.response.status >= 400 && error.response.status < 500) {
                    return false;
                }
            }
            // Retry for server-side errors (5xx) or network issues
            return failureCount < 3;
        },
        retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000, 
        cacheTime: 5 * 60 * 1000,
    });

    // Reset competition page when filters change
    useEffect(() => {
        setCompetitionPage(0);
    }, [criteria]);

    const competitionsQuery = useQuery({
        queryKey: ['admin', 'competitions', competitionPage, criteria, session],
        queryFn: () => getCompetitions("all", competitionPage, criteria, session, i18next.language, "all"),
        enabled: indexPage === 1 && !!session,
        retry: (failureCount, error) => {
            if (error.response) {
                if (error.response.status >= 400 && error.response.status < 500) {
                    return false;
                }
            }
            // Retry for server-side errors (5xx) or network issues
            return failureCount < 3;
        },
        retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000,
        onError: (error, variables, context) => {
            removeFeedback();
            if (context?.failureCount >= 3) {
                setErrorCompetitionsLoading(true);
            }
        },
        onSuccess: (data) => {
            setErrorCompetitionsLoading(false);
            if (data.competitions.length > 0) {
                setCompetitionFeedbackMessage({ type: 'success', message: i18next.t('competitions-found') });
            } else {
                setCompetitionFeedbackMessage({ type: 'warn', message: i18next.t('no-competitions-found') });
            }
        },
        onSettled: () => {
        }
    });

    const handleCompetitionsReload = () => {
        setErrorCompetitionsLoading(false);
        competitionsQuery.refetch();
    };

    const handleCompetitionSearch = (newCriteria) => {
        setCompetitionFeedbackMessage(null);
        setCriteria(newCriteria);
    };

    useEffect(() => {
        if (competitionsQuery.isSuccess && competitionsQuery.data) {
            setCompetitions(competitionsQuery.data.competitions);
        }
    }, [competitionsQuery.isSuccess, competitionsQuery.data]);

    // CompetitionView Mutation

    const competitionViewMutation = useMutation({
        mutationFn: (competitionGUID) => viewAdminCompetition(competitionGUID, session, i18next.language),
        onMutate: () => {
            createModal(<SkeletonModal />, true, true);
        },
        onError: (error) => {
            removeModal()
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            removeFeedback();
            navigate('/admin/panel');
        }
    });

    const handleCompetitionViewSuccess = (guid) => {
        
        const onCancel = () => {
            removeModal();
        };

        competitionViewMutation.mutate(guid, {
            onSuccess: (data) => {
                createModal(
                    <CompetitionViewModal
                        onCancel={onCancel}
                        data={data}
                    />, true, true
                );
            }
        });
    };

    /**
     * Create a new competition
     */
    const handleCompetitionCreate = () => {
        navigate('/competitions/create');
    };

    /**
     * Update Competition
     */
    const handleCompetitionUpdate = (guid) => {
        navigate(`/competitions/update/${guid}`);
    };

    const competitionDeleteMutation = useMutation({
        mutationFn: (guid) => deleteAdminCompetition(guid, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('competition-deleted-successfully'));
            const queryKeyCompetitions = ['admin', 'competitions', competitionPage, criteria, session];
            queryClient.invalidateQueries(queryKeyCompetitions);
        }
    });
    
    /**
     * Delete Competition
     */
    const handleCompetitionDelete = (compInfo) => {
        // Delete a Competition
        const onAccept = () => {
            removeModal();
            if (!compInfo || !compInfo.guid) return;
            
            competitionDeleteMutation.mutate(compInfo.guid);
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <CompetitionDeleteModal
                name={compInfo.name}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };

    // User Logic

    const [errorUsersLoading, setErrorUsersLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [userFeedbackMessage, setUserFeedbackMessage] = useState(null);

    const [userFilters, setUserFilters] = useState({
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        role: '',
        status: ''
    });

    const userQueryKey = [session, 'admin', 'users', userPage, perPage, userFilters];

    useEffect(() => {
        setUserPage(0);
    }, [userFilters]);

    const usersQuery = useQuery({
        queryKey: userQueryKey,
        queryFn: () => getAdminUsers(
            userPage, 
            perPage, 
            session, 
            i18next.language, 
            userFilters.email || null,
            userFilters.username || null,
            userFilters.status || null,
            userFilters.firstName || null,
            userFilters.lastName || null,
            userFilters.role || null),
        enabled: indexPage === 3 && !!session,
        retry: (failureCount, error) => {
            if (error.response) {
                if (error.response.status >= 400 && error.response.status < 500) {
                    return false;
                }
            }
            // Retry for server-side errors (5xx) or network issues
            return failureCount < 3;
        },
        retryDelay: (attemptIndex) => Math.min(2000 * 2 ** attemptIndex, 30000),
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        onError: (error, variables, context) => {
            removeFeedback();
            if (context?.failureCount >= 3) {
                setErrorUsersLoading(true);
            }
        },
        onSuccess: (data) => {
            setErrorUsersLoading(false);
            if (data.users.length > 0) {
                setUserFeedbackMessage({ type: 'success', message: i18next.t('users-found') });
            } else {
                setUserFeedbackMessage({ type: 'warn', message: i18next.t('no-users-found') });
            }
        },
        onSettled: () => {
        }
    });

    const handleUsersReload = () => {
        setErrorUsersLoading(false);
        usersQuery.refetch();
    };

    const handleUserSearch = (searchUserParams) => {
        setUserFeedbackMessage(null);
        setUserFilters(searchUserParams);
    };

    useEffect(() => {
        if (usersQuery.isSuccess && usersQuery.data) {
            setUsers(usersQuery.data.users);
        }
    }, [usersQuery.isSuccess, usersQuery.data]);


    const usersCreateMutation = useMutation({
        mutationFn: (user) =>
            createAdminUser(
                user.email,
                user.password,
                user.country,
                user.firstName,
                user.lastName,
                user.sex,
                user.birthday,
                user.website,
                user.biography,
                user.phone,
                user.twitter,
                user.facebook,
                user.linkedin,
                user.github,
                session,
                i18next.language
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('user-created-successfully'));

            const queryKeyUsers = [session, 'admin', 'users', userPage, perPage, userFilters];
            queryClient.invalidateQueries(queryKeyUsers);
            setUserPage(0);
        }
      });

    /**
     * Create a new user user
     */
    const handleUserCreate = () => {
        if (countriesQuery.isLoading) {
            return;
        }

        const onAccept = () => {
            
            const trimmedEmail = emailRef.current.value.trim();
            const trimmedFirstName = firstNameRef.current.value.trim();
            const trimmedLastName = lastNameRef.current.value.trim();
            const trimmedWeb = webRef?.current?.value.trim();
            const trimmedBiography = biographyRef?.current?.value.trim();
            const trimmedPhone = phoneRef?.current?.value.trim();
            const trimmedTwitter = twitterRef?.current?.value.trim();
            const trimmedFacebook = facebookRef?.current?.value.trim();
            const trimmedLinkedin = linkedinRef?.current?.value.trim();
            const trimmedGithub = githubRef?.current?.value.trim
            
            if (
                !trimmedEmail ||
                !passwordRef.current.value ||
                !countryRef.current ||
                !trimmedFirstName ||
                !trimmedLastName ||
                !sexRef.current
            ) {
                return;
            }

            usersCreateMutation.mutate({
                email: trimmedEmail,
                password: passwordRef.current.value,
                country: countryRef.current,
                firstName: trimmedFirstName,
                lastName: trimmedLastName,
                sex: sexRef.current,
                birthday: birthdayRef?.current.value,
                website: trimmedWeb,
                biography: trimmedBiography,
                phone: trimmedPhone,
                twitter: trimmedTwitter,
                facebook: trimmedFacebook,
                linkedin: trimmedLinkedin,
                github: trimmedGithub
            });

            removeModal();
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <UserCreateModal
                emailRef={emailRef}
                passwordRef={passwordRef}
                countryRef={countryRef}
                firstNameRef={firstNameRef}
                lastNameRef={lastNameRef}
                sexRef={sexRef}
                birthdayRef={birthdayRef}
                webRef={webRef}
                biographyRef={biographyRef}
                phoneRef={phoneRef}
                twitterRef={twitterRef}
                facebookRef={facebookRef}
                linkedinRef={linkedinRef}
                githubRef={githubRef}
                countries={countriesQuery.data || {}}
                onAccept={onAccept}
                onCancel={onCancel}
            />, true
        );
    };

    // UserView Mutation

    const handleUserView = (userInfoObj) => {
        
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <UserViewModal
                onCancel={onCancel}
                data={userInfoObj}
            />, true, true
        );
    };

    const userUpdateMutation = useMutation({
        mutationFn: (user) => updateAdminUser(user.guid, user.email, user.country, user.firstName, user.lastName,
            user.sex, user.birthday, user.website, user.biography, user.phone, user.twitter, user.facebook, user.linkedin,
                user.github, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('user-updated-successfully'));
            queryClient.invalidateQueries(userQueryKey);
        }
    });

    /**
     * Update User
     */
    const handleUserUpdate = (userInfoObj) => {
        if (countriesQuery.isLoading || countriesQuery.isError) {
            return;
        }

        const onAccept = () => {
            if (
                !emailRef.current.value ||
                !firstNameRef.current.value ||
                !lastNameRef.current.value ||
                !sexRef.current ||
                !countryRef.current
            ) {
                return;
            }

            const trimmedEmail = emailRef.current.value.trim();
            const trimmedFirstName = firstNameRef.current.value.trim();
            const trimmedLastName = lastNameRef.current.value.trim();
            const trimmedWeb = webRef?.current?.value.trim();
            const trimmedBiography = biographyRef?.current?.value.trim();
            const trimmedPhone = phoneRef?.current?.value.trim();
            const trimmedTwitter = twitterRef?.current?.value.trim();
            const trimmedFacebook = facebookRef?.current?.value.trim();
            const trimmedLinkedin = linkedinRef?.current?.value.trim();
            const trimmedGithub = githubRef?.current?.value.trim

            userUpdateMutation.mutate({
                guid: userInfoObj.guid,
                email: trimmedEmail,
                country: countryRef.current,
                firstName: trimmedFirstName,
                lastName: trimmedLastName,
                sex: sexRef.current,
                birthday: birthdayRef?.current.value,
                website: trimmedWeb,
                biography: trimmedBiography,
                phone: trimmedPhone,
                twitter: trimmedTwitter,
                facebook: trimmedFacebook,
                linkedin: trimmedLinkedin,
                github: trimmedGithub
            });

            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <UserUpdateModal
                emailRef={emailRef}
                countryRef={countryRef}
                firstNameRef={firstNameRef}
                lastNameRef={lastNameRef}
                sexRef={sexRef}
                birthdayRef={birthdayRef}
                webRef={webRef}
                biographyRef={biographyRef}
                phoneRef={phoneRef}
                twitterRef={twitterRef}
                facebookRef={facebookRef}
                linkedinRef={linkedinRef}
                githubRef={githubRef}
                countries={countriesQuery.data || {}}
                onAccept={onAccept}
                onCancel={onCancel}
                userInfoObj={{
                    guid: userInfoObj && userInfoObj.guid ? userInfoObj.guid?.toString() : '',
                    email: userInfoObj && userInfoObj.email ? userInfoObj.email : '',
                    firstName: userInfoObj && userInfoObj.firstName ? userInfoObj.firstName : '',
                    lastName: userInfoObj && userInfoObj.lastName ? userInfoObj.lastName : '',
                    sex: userInfoObj && userInfoObj.sex ? userInfoObj.sex : '',
                    birthday: userInfoObj && userInfoObj.birthday ? userInfoObj.birthday : '',
                    website: userInfoObj && userInfoObj.website ? userInfoObj.website : '',
                    biography: userInfoObj && userInfoObj.biography ? userInfoObj.biography : '',
                    phone: userInfoObj && userInfoObj.phone ? userInfoObj.phone : '',
                    twitter: userInfoObj && userInfoObj.twitter ? userInfoObj.twitter : '',
                    facebook: userInfoObj && userInfoObj.facebook ? userInfoObj.facebook : '',
                    linkedin: userInfoObj && userInfoObj.linkedin ? userInfoObj.linkedin : '',
                    github: userInfoObj && userInfoObj.github ? userInfoObj.github : '',
                    country: userInfoObj && userInfoObj.country ? String(userInfoObj.country) : '',
                }}
            />, true
        );
    };

    const userChangeStatusMutation = useMutation({
        mutationFn: ({ guid, userStatus }) => {
            let mutationPromise;

            if (userStatus === "DELETED") {
                mutationPromise = activateAdminUser(guid, session, i18next.language);
            } else if (userStatus === "ACTIVE") {
                mutationPromise = deleteAdminUser(guid, session, i18next.language);
            }
            
            return mutationPromise;
        },
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: ({title}) => {
            if (title === "ACTIVE") {
                createFeedback('success', i18next.t('user-activated-successfully'));
            } else if (title === "DELETED") {
                createFeedback('success', i18next.t('user-deleted-successfully'));
            }
            
            const queryKeyUsers = [session, 'admin', 'users', userPage, perPage, userFilters];
            setUserPage(0);
            queryClient.invalidateQueries(queryKeyUsers);
        }
    });
    
    /**
     * Delete/Activate User
     */
    const handleUserState = (guid, email, status) => {
        const onAccept = () => {
            removeModal();
            if (!guid || !email || !status) return;
            
            userChangeStatusMutation.mutate({ guid, userStatus: status });
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <UserStatusModal
                email={email}
                status={status}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };

    const usersBanMutation = useMutation({
        mutationFn: ({ guid, reason, endDate, comment }) => 
            banAdminUser(guid, reason, endDate, comment, session, i18next.language)
        ,
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            
            createFeedback('success', i18next.t('user-banned-successfully'));

            const queryKeyUsers = [session, 'admin', 'users', userPage, perPage, userFilters];
            queryClient.invalidateQueries(queryKeyUsers);
        }
    });
    
    /**
     * Ban User
     */
    const handleUserBan = (guid) => {
        const onAccept = (banEndDate, banComment) => {
            removeModal();
            if (
                !guid ||
                !banReasonRef.current
            ) {
                return;
            }
            const formattedBanEndDate = banEndDate
                ? datetimeToUTC(banEndDate)
                : null;

            usersBanMutation.mutate({
                guid,
                reason: banReasonRef.current,
                endDate: formattedBanEndDate,
                comment: banComment
            });
        }
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <BanModal
                banReasonRef={banReasonRef}
                banEndRef={banEndRef}
                banCommRef={banCommRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };


    /**
     * Ban User Detail
     */
    const handleUserBanDetail = (username, firstName, lastName, email, reason, startDate, endDate, comment) => {
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <BanDetailModal
                username={username}
                email={email}
                firstName={firstName}
                lastName={lastName}
                reason={reason}
                startDate={startDate}
                endDate={endDate}
                comment={comment}
                onCancel={onCancel}
            />
        );
    };

    // Challenge Logic

    const [errorChallengesLoading, setErrorChallengesLoading] = useState(false);

    const [challenges, setChallenges] = useState([]);
    const [challengeFeedbackMessage, setChallengeFeedbackMessage] = useState(null);
    
    
    const [challengeFilters, setChallengeFilters] = useState({
        challengeName: '',
        categories: [],
        skills: [],
        answerType: '',
    });
    
    // Reset challenge page when filters change
    useEffect(() => {
        setChallengePage(0);
    }, [challengeFilters]);

    const challengeQueryKey = [session, 'admin', 'challenges', challengePage, perPage, challengeFilters];

    // Get challenges
    const challengesQuery = useQuery({
        queryKey: challengeQueryKey,
        queryFn: () => getAdminChallenges(
            challengePage, 
            perPage, 
            session, 
            i18next.language,
            challengeFilters.challengeName || null, 
            challengeFilters.categories || null, 
            challengeFilters.skills || null,
            challengeFilters.answerType || null),
        enabled: indexPage === 2 && !!session,
        retry: (failureCount, error) => {
            if (error.response) {
                if (error.response.status >= 400 && error.response.status < 500) {
                    return false;
                }
            }
            // Retry for server-side errors (5xx) or network issues
            return failureCount < 3;
        },
        retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000,
        onError: (error, variables, context) => {
            removeFeedback();
            if (context?.failureCount >= 3) {
                setErrorChallengesLoading(true);
            }
        },
        onSuccess: (data) => {
            setErrorChallengesLoading(false);
            if (data.challenges.length > 0) {
                setChallengeFeedbackMessage({ type: 'success', message: i18next.t('challenges-found') });
            } else {
                setChallengeFeedbackMessage({ type: 'warn', message: i18next.t('no-challenges-found') });
            }
        },
    });

    const handleChallengesReload = () => {
        setErrorChallengesLoading(false);
        challengesQuery.refetch();
    };

    const handleChallengeSearch = ({ challengeName, selectedCategories, selectedSkills, selectedType }) => {
        setChallengeFeedbackMessage(null);
        setChallengeFilters({
            challengeName,
            categories: selectedCategories,
            skills: selectedSkills,
            answerType: selectedType,
        });
    };

    useEffect(() => {
        if (challengesQuery.isSuccess && challengesQuery.data) {
            setChallenges(challengesQuery.data.challenges);
        }
    }, [challengesQuery.isSuccess, challengesQuery.data]);


    const challengeCreateMutation = useMutation({
        mutationFn: (cha) =>
            createAdminChallenge(
                cha.name,
                cha.type,
                cha.status,
                cha.visibility,
                cha.description,
                cha.clarifications,
                cha.maximum,
                cha.scoreType,
                cha.score,
                cha.category,
                cha.answers,
                cha.skills,
                session,
                i18next.language
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('challenge-created-successfully'));

            queryClient.invalidateQueries(challengeQueryKey);
            
            const totalChPages = challengesQuery.data?.pages || 1;

            const totalChElements = challengesQuery.data?.length || 1;

            (totalChElements % perPage === 0) ? setChallengePage(totalChPages) : setChallengePage(totalChPages - 1);
        }
      });

    /**
     * Create a new challenge
     */
    const handleChallengeCreate = () => {
        // Categories and skills are necesary to create a challenge
        if (categoryListQuery.isLoading || categoryListQuery.isError) {
            window.scrollTo(0, 0);
            createFeedback('error', i18next.t('error-loading-categories'));
            return;
        }

        // Skills are necesary to create a challenge
        if(allSkillsQuery.isLoading || allSkillsQuery.isError) {
            window.scrollTo(0, 0);
            createFeedback('error', i18next.t('error-loading-skills'));
            return;
        }

        const onAccept = (answers, selectedSkills) => {

            const trimmedName = nameChaRef.current.value.trim();
            const trimedMaximumAttempts = maximumRef?.current?.value.trim() || null;
            
            if (
                !trimmedName ||
                !chaTypeRef.current ||
                !statusChaRef.current ||
                !visibilityChaRef.current ||
                !descriptionChaRef.current ||
                !trimedMaximumAttempts ||
                !chaCatRef.current ||
                !scoreTypeRef.current
            ) {
                return;
            }

            const filteredAnswers = answers.map(answer => ({
                ansType: chaTypeRef.current,
                data: answer.data,
                valid: answer.valid
            }));

            if (chaTypeRef.current === 'PLAIN' && filteredAnswers.length < 2) {
                createFeedback('warn', i18next.t('at-least-two-answers'));
                return;
            }
        
            if (chaTypeRef.current !== 'PLAIN' && filteredAnswers.length === 0) {
                createFeedback('warn', i18next.t('at-least-one-answer'));
                return;
            }      

            challengeCreateMutation.mutate({
                name: trimmedName,
                type: chaTypeRef.current,
                status: statusChaRef.current,
                visibility: visibilityChaRef.current,
                description: descriptionChaRef.current,
                clarifications: clarificationsChaRef.current || '',
                maximum: trimedMaximumAttempts || 1,
                category: chaCatRef.current,
                scoreType: scoreTypeRef.current,
                score: scoreRef.current.value || 0,
                skills: selectedSkills,
                answers: filteredAnswers
            });

            removeModal();
        };
    
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <ChallengeCreateModal
                nameChaRef={nameChaRef}
                chaTypeRef={chaTypeRef}
                statusChaRef={statusChaRef}
                visibilityChaRef={visibilityChaRef}
                descriptionChaRef={descriptionChaRef}
                clarificationsChaRef={clarificationsChaRef}
                maximumRef={maximumRef}
                chaCatRef={chaCatRef}
                scoreTypeRef={scoreTypeRef}
                scoreRef={scoreRef}
                categories={categoryListQuery.data || {}}
                skills={allSkillsQuery.data?.skills || {}}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            true, true, false
        );
    };

    const challengeUpdateMutation = useMutation({
        mutationFn: (cha) => updateAdminChallenge(
            cha.guid,
            cha.name,
            cha.type,
            cha.status,
            cha.visibility,
            cha.description,
            cha.clarifications,
            cha.maximum,
            cha.scoreType,
            cha.score,
            cha.category,
            cha.answers,
            cha.skills,
            session,
            i18next.language
        ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('challenge-updated-successfully'));
    
            const queryKeyChallenges = [session, 'admin', 'challenges', challengePage, perPage];
            queryClient.invalidateQueries(queryKeyChallenges);
        }
    });


    const handleChallengeView = (chaInfoObj) => {
        
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <ChallengeViewModal
                data={chaInfoObj}
                onCancel={onCancel}
            />, true, true
        );
    };

    /**
     * Update Challenge
     */
    const handleChallengeUpdate = (challengeInfoObj) => {
        // Categories are necesary to create a challenge
        if (categoryListQuery.isLoading || categoryListQuery.isError) {
            window.scrollTo(0, 0);
            createFeedback('error', i18next.t('error-loading-categories'));
            return;
        }

        if(allSkillsQuery.isLoading || allSkillsQuery.isError) {
            window.scrollTo(0, 0);
            createFeedback('error', i18next.t('error-loading-skills'));
            return;
        }

        const onAccept = (answers, selectedSkills) => {

            
            const trimmedName = nameChaRef.current.value.trim();
            const trimedMaximumAttempts = maximumRef?.current?.value.trim() || null;

            if (
                !trimmedName ||
                !statusChaRef.current ||
                !visibilityChaRef.current ||
                !descriptionChaRef.current ||
                !trimedMaximumAttempts ||
                !chaCatRef.current ||
                !scoreTypeRef.current
            ) {
                return;
            }

            const filteredAnswers = answers.map(answer => ({
                GUID: answer.guid,
                ansType: challengeInfoObj.type,
                data: answer.data,
                valid: answer.valid
            }));

            if (challengeInfoObj.type === 'PLAIN' && filteredAnswers.length < 2) {
                createFeedback('warn', i18next.t('at-least-two-answers'));
                return;
            }
        
            if (challengeInfoObj.type !== 'PLAIN' && filteredAnswers.length === 0) {
                createFeedback('warn', i18next.t('at-least-one-answer'));
                return;
            } 

            challengeUpdateMutation.mutate({
                guid: challengeInfoObj.chaGUID,
                name: trimmedName,
                type: challengeInfoObj.type,
                status: statusChaRef.current,
                visibility: visibilityChaRef.current,
                description: descriptionChaRef.current,
                clarifications: clarificationsChaRef.current || '',
                maximum: trimedMaximumAttempts || 1,
                scoreType: scoreTypeRef.current,
                score: scoreRef.current.value || 0,
                category: chaCatRef.current,
                answers: filteredAnswers,
                skills: selectedSkills
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <ChallengeUpdateModal
                nameChaRef={nameChaRef}
                statusChaRef={statusChaRef}
                visibilityChaRef={visibilityChaRef}
                descriptionChaRef={descriptionChaRef}
                clarificationsChaRef={clarificationsChaRef}
                maximumRef={maximumRef}
                chaCatRef={chaCatRef}
                scoreTypeRef={scoreTypeRef}
                scoreRef={scoreRef}
                categories={categoryListQuery.data || {}}
                countries={countriesQuery.data || {}}
                skills={allSkillsQuery.data?.skills || {}}
                challengeInfoObj={{
                    guid: challengeInfoObj && challengeInfoObj.chaGUID ? challengeInfoObj.chaGUID?.toString() : '',
                    name: challengeInfoObj && challengeInfoObj.name ? challengeInfoObj.name : '',
                    type: challengeInfoObj && challengeInfoObj.type ? challengeInfoObj.type : '',
                    status: challengeInfoObj && challengeInfoObj.status ? challengeInfoObj.status : '',
                    visibility: challengeInfoObj && challengeInfoObj.visibility ? challengeInfoObj.visibility : '',
                    description: challengeInfoObj && challengeInfoObj.description ? challengeInfoObj.description : '',
                    clarifications: challengeInfoObj && challengeInfoObj.clarifications ? challengeInfoObj.clarifications : '',
                    maximum: challengeInfoObj && challengeInfoObj.maximum ? challengeInfoObj.maximum : '',
                    scoreType: challengeInfoObj && challengeInfoObj.scoreType ? challengeInfoObj.scoreType : '',
                    score: challengeInfoObj && challengeInfoObj.score ? challengeInfoObj.score : '',
                    category: challengeInfoObj && challengeInfoObj.category ? challengeInfoObj.category : '',
                    answers: challengeInfoObj && challengeInfoObj.answers ? challengeInfoObj.answers : [],
                    skills: challengeInfoObj && challengeInfoObj.skills ? challengeInfoObj.skills : [],
                }}
                onAccept={onAccept}
                onCancel={onCancel}
            />, true, true, false
        );
    };

    const challengeDeleteMutation = useMutation({
        mutationFn: (guid) => deleteAdminChallenge(guid, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('challenge-deleted-successfully'));
            
            const queryKeyChallenges = [session, 'admin', 'challenges', challengePage, perPage];
            queryClient.invalidateQueries(queryKeyChallenges);
        }
    });
    
    /**
     * Delete Challenge
     */
    const handleChallengeDelete = (chaInfo) => {
        const onAccept = () => {
            removeModal();

            if (!chaInfo || !chaInfo.guid) return;
            
            challengeDeleteMutation.mutate(chaInfo.guid);
        };
    
        const onCancel = () => {
            removeModal();
        };
    
        createModal(
            <ChallengeDeleteModal
                name={chaInfo.name}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };

    function getColorForStatus(status) {
        switch (status) {
            case 'PRODUCTION':
                return 'green';
            case 'DELETED':
                return 'red';
            case 'QUEUE':
                return 'blue';
            case 'NOT_APPROVED':
                return 'orange';
            case 'DISABLED':
                return 'gray';
            case 'STAGING':
                return 'yellow';
            default:
                return 'gray';
        }
    }

    function getColorForType(type) {
        switch (type) {
            case 'HASH':
                return 'yellow';
            case 'REGEXP':
                return 'red';
            case 'DYNAMIC':
                return 'green';
            case 'PLAIN':
                return 'blue';
            case 'CODE':
                return 'gray';
            case 'OPEN':
                return 'orange';
            default:
                return 'gray';
        }
    }

    function getColorForScore(score) {
        if (score >= 75) {
            return 'green';
        }
        if (score >= 50) {
            return 'yellow';
        }
        if (score >= 25) {
            return 'orange';
        }
        return 'red';
    }

    /**
     * Change the showed page
     * @param {number} actualPage
     */
    const changePage = (actualPage) => {
        setIndexPage(actualPage);
        removeFeedback();
    };

    // Handle session
    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    const categoriesContent = () => (
        <div className="flex w-full flex-col gap-y-2">
            {errorCategoriesLoading ? (
                <div className="w-1/6 flex self-center">
                    <Button
                        color='green'
                        icon={faArrowsRotate}
                        onClick={handleCategoriesReload}
                    >
                        {i18next.t('reload')}
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full flex items-center">
                        <div className="w-1/12 flex items-center justify-center">
                            <Button
                                circle
                                isLoading={categoriesQuery.isLoading}
                                color="blue"
                                    icon={faPlus}
                                    title={i18next.t('create-new')}
                                    onClick={handleCategoryCreate}
                                />
                        </div>
                        <div className="w-11/12">
                            <Banner color="green" borderColor='green'>Categories</Banner>
                        </div>
                    </div>
                    <SimpleSearch 
                        isLoading={categoriesQuery.isLoading} 
                        placeholder={i18next.t('search-categories')}
                        onSearch={handleCategorySearch} 
                    />
                    {searchCategoryTerm && (
                        <Feedback type="success">
                            {i18next.t('categories-found')} <i>&quot;{searchCategoryTerm}&quot;</i>
                        </Feedback>
                    )}
                    <Container>
                        <div className="w-full divide-y">
                            <div className="w-full flex p-2 gap-2">
                                <div className="w-2/12 font-semibold text-center">
                                    <Title isLoading={categoriesQuery.isLoading} size="lg">{i18next.t('challenges-amount')}</Title>
                                </div>
                                <div className="w-2/12 font-semibold">
                                    <Title isLoading={categoriesQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                </div>
                                
                                <div className="w-5/12 font-semibold">
                                    <Title isLoading={categoriesQuery.isLoading} size="lg">{i18next.t('description')}</Title>
                                </div>
                                <div className="w-3/12 font-semibold text-center">
                                    <Title isLoading={categoriesQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                </div>
                            </div>
                            {categoriesQuery.isLoading ? (
                                <Repeat count={perPage}>
                                    <div className="w-full flex p-4 gap-4">
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-16 h-5 flex-shrink-0"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-2/4 h-5"
                                        />
                                    </div>
                                </Repeat>
                            ) : (
                                categories && categories.map((catInfoObj) => (
                                    <div key={catInfoObj.id} className="flex w-full p-2 items-center">
                                        <div className="w-2/12 text-center">{catInfoObj.challengeCount}</div>
                                        <div className="w-2/12">{catInfoObj.name}</div>
                                        <div className="w-5/12">{catInfoObj.description}</div>
                                        <div className="w-3/12 font-semibold">
                                            <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() => handleCategoryUpdate(catInfoObj)}
                                                        >
                                                            {i18next.t('edit')}
                                                    </Button>
                                                </div>
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="red"
                                                        icon={faTrash}
                                                        onClick={() => handleCategoryDelete({
                                                            catID: catInfoObj.id,
                                                            name: catInfoObj.name,
                                                        })}
                                                    >
                                                        {i18next.t('delete')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Container> 
                </>
            )}
            {!errorCategoriesLoading && (
                !categoriesQuery.isLoading && categoriesQuery.data ? (
                    <div className="w-full flex justify-center">
                        <Pager pages={categoriesQuery.data.pages} page={categoryPage} setPage={setCategoryPage} />
                    </div>
                ) : (
                    <div className="w-full flex justify-center p-4 gap-4">
                        <Repeat count={14}>
                            <Skeleton
                                circle
                                count={1}
                                baseColor="#e4e4e7"
                                highlightColor="#f4f4f5"
                                containerClassName="w-9"
                                className='h-9'
                            />
                        </Repeat>
                    </div>
                )
            )}
        </div>
    );

    const skillsContent = () => (
        <div className="flex w-full flex-col gap-y-2">
            {errorSkillsLoading ? (
                <div className="w-1/6 flex self-center">
                    <Button
                        color='green'
                        icon={faArrowsRotate}
                        onClick={handleSkillsReload}
                    >
                        {i18next.t('reload')}
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full flex items-center">
                        <div className="w-1/12 flex items-center justify-center">
                            <Button
                                circle
                                isLoading={skillsQuery.isLoading}
                                color="blue"
                                    icon={faPlus}
                                    title={i18next.t('create-new')}
                                    onClick={handleSkillCreate}
                                />
                        </div>
                        <div className="w-11/12">
                            <Banner color="green" borderColor='green'>Skills</Banner>
                        </div>
                    </div>
                    <SimpleSearch 
                        isLoading={skillsQuery.isLoading} 
                        placeholder={i18next.t('search-skills')}
                        onSearch={handleSkillSearch} 
                    />
                    {searchSkillTerm && (
                        <Feedback type="success">
                            {i18next.t('skills-found')} <i>&quot;{searchSkillTerm}&quot;</i>
                        </Feedback>
                    )}
                    <Container>
                        <div className="w-full divide-y">
                            <div className="w-full flex p-2 gap-2">
                                <div className="w-3/12 font-semibold">
                                    <Title isLoading={skillsQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                </div>
                                <div className="w-7/12 font-semibold text-center">
                                    <Title isLoading={skillsQuery.isLoading} size="lg">{i18next.t('description')}</Title>
                                </div>
                                <div className="w-2/12 font-semibold text-center">
                                    <Title isLoading={skillsQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                </div>
                            </div>
                            {skillsQuery.isLoading ? (
                                <Repeat count={perPage}>
                                    <div className="w-full flex p-4 gap-4">
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-16 h-5 flex-shrink-0"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-2/4 h-5"
                                        />
                                    </div>
                                </Repeat>
                            ) : (
                                skills && skills.map((skillInfoObj) => (
                                    <div key={skillInfoObj.id} className="flex w-full p-2 items-center">
                                        <div className="w-3/12">{skillInfoObj.name}</div>
                                        <div className="w-7/12">{skillInfoObj.description}</div>
                                        <div className="w-2/12 font-semibold">
                                            <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() => handleSkillUpdate(skillInfoObj)}
                                                        >
                                                            {i18next.t('edit')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Container> 
                </>
            )}
            {!errorSkillsLoading && (
                !skillsQuery.isLoading && skillsQuery.data ? (
                    <div className="w-full flex justify-center">
                        <Pager pages={skillsQuery.data.pages} page={skillPage} setPage={setSkillPage} />
                    </div>
                ) : (
                    <div className="w-full flex justify-center p-4 gap-4">
                        <Repeat count={14}>
                            <Skeleton
                                circle
                                count={1}
                                baseColor="#e4e4e7"
                                highlightColor="#f4f4f5"
                                containerClassName="w-9"
                                className='h-9'
                            />
                        </Repeat>
                    </div>
                )
            )}
        </div>
    );

    const challengesContent = () => (
        <div className="flex w-full flex-col gap-y-2">
            {errorChallengesLoading ? (
                <div className="w-1/6 flex self-center">
                    <Button
                        color='green'
                        icon={faArrowsRotate}
                        onClick={handleChallengesReload}
                    >
                        {i18next.t('reload')}
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full flex items-center">
                        <div className="w-1/12 flex items-center justify-center">
                            <Button
                                circle
                                isLoading={challengesQuery.isLoading}
                                color="blue"
                                icon={faPlus}
                                title={i18next.t('create-new')}
                                onClick={handleChallengeCreate}
                            />
                        </div>
                        <div className="w-11/12">
                            <Banner color="green" borderColor='green'>Challenges</Banner>
                        </div>
                    </div>
                    <ChallengeAdminSearch
                        isLoading={challengesQuery.isLoading}
                        onSearch={handleChallengeSearch} 
                        feedbackMessage={challengeFeedbackMessage}
                    />
                    <Container>
                        <div className="w-full divide-y">
                            <div className="w-full flex items-center py-2 gap-x-3">
                                <div className="flex-none w-[8%] text-center font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('status')}</Title>
                                </div>
                                <div className="flex-none w-[8%] text-center font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('category')}</Title>
                                </div>
                                <div className="flex-none w-[8%] text-center font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('type')}</Title>
                                </div>
                                <div className="flex-grow font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                </div>
                                <div className="flex-none w-[8%] text-center font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('max-attempts')}</Title>
                                </div>
                                <div className="flex-none w-[8%] text-center font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('score-type')}</Title>
                                </div>
                                <div className="flex-none w-[8%] text-center font-semibold">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('score')}</Title>
                                </div>
                                <div className="flex-none w-[20%] font-semibold text-center">
                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                </div>
                            </div>
                            {challengesQuery.isLoading ? (
                                <Repeat count={perPage}>
                                    <div className="w-full flex p-4 gap-4">
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-16 h-5 flex-shrink-0"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-2/4 h-5"
                                        />
                                    </div>
                                </Repeat>
                            ) : (
                                challenges && challenges.map((chaInfoObj) => (
                                    <div key={chaInfoObj.chaGUID} className="flex w-full py-2 items-center gap-x-3">
                                        <div className="flex-none w-[8%] text-center" title={chaInfoObj.status}>
                                            <FontAwesomeIcon color={getColorForStatus(chaInfoObj.status)} icon={faCircle} />
                                        </div>
                                        <div className="flex-none w-[8%] text-center">
                                            {chaInfoObj.categoryName}
                                        </div>
                                        <div className="flex-none w-[8%] flex justify-center">
                                            {["HASH", "REGEXP", "DYNAMIC", "PLAIN", "CODE", "OPEN"].includes(chaInfoObj.type) ? (
                                                <Tag size="sm" color={getColorForType(chaInfoObj.type)}>
                                                    {chaInfoObj.type}
                                                </Tag>
                                            ) : (
                                                <Tag size="sm" color="gray">
                                                    {i18next.t('unknown')}
                                                </Tag>
                                            )}
                                        </div>
                                        <div className="flex-grow">{chaInfoObj.name}</div>
                                        <div className="flex-none w-[8%] text-center">
                                            {chaInfoObj.maximum}
                                        </div>
                                        <div className="flex-none w-[8%] text-center">
                                            {chaInfoObj.scoreType === "VARIABLE" ? (
                                                "VARIABLE"
                                            ) : (
                                                "FIXED"
                                            )}
                                        </div>
                                        <div className="flex-none w-[8%] flex justify-center">
                                            <Tag
                                                size="sm"
                                                color={getColorForScore(chaInfoObj.score)}
                                                centerText
                                            >
                                                {chaInfoObj.score}
                                            </Tag>
                                        </div>
                                        <div className="flex-none w-[20%] text-center font-semibold">
                                            <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                <div>
                                                        <Button
                                                            color="green"
                                                            icon={faEye}
                                                            onClick={() => handleChallengeView(chaInfoObj)}
                                                        />
                                                </div>
                                                <div>
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() => handleChallengeUpdate(chaInfoObj)}
                                                    />
                                                </div>
                                                <div>
                                                    <Button
                                                        color="red"
                                                        icon={faTrash}
                                                        onClick={() => handleChallengeDelete({
                                                            guid: chaInfoObj.chaGUID,
                                                            name: chaInfoObj.name,
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Container> 
                </>  
            )}
            {!errorChallengesLoading && (
                !challengesQuery.isLoading && challengesQuery.data ? (
                    <div className="w-full flex justify-center">
                        <Pager pages={challengesQuery.data.pages} page={challengePage} setPage={setChallengePage} />
                    </div>
                ) : (
                    <div className="w-full flex justify-center p-4 gap-4">
                        <Repeat count={14}>
                            <Skeleton
                                circle
                                count={1}
                                baseColor="#e4e4e7"
                                highlightColor="#f4f4f5"
                                containerClassName="w-9"
                                className='h-9'
                            />
                        </Repeat>
                    </div>
                )
            )}
        </div>
    );

    const competitionsContent = () => (
        <div className="flex w-full flex-col gap-y-2">
            {errorCompetitionsLoading ? (
                <div className="w-1/6 flex self-center">
                    <Button
                        color='green'
                        icon={faArrowsRotate}
                        onClick={handleCompetitionsReload}
                    >
                        {i18next.t('reload')}
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full flex items-center">
                        <div className="w-1/12 flex items-center justify-center">
                            <Button
                                circle
                                isLoading={competitionsQuery.isLoading}
                                color="blue"
                                icon={faPlus}
                                title={i18next.t('create-new')}
                                onClick={handleCompetitionCreate}
                            />
                        </div>
                        <div className="w-11/12">
                            <Banner color="green" borderColor='green'>{i18next.t('competitions')}</Banner>
                        </div>
                    </div>
                    <CompetitionAdminSearch 
                        isLoading={competitionsQuery.isLoading} 
                        onSearch={handleCompetitionSearch} 
                        feedbackMessage={competitionFeedbackMessage}
                    />
                    <Container>
                        <div className="w-full divide-y">
                            <div className="w-full flex p-2 gap-2">
                                <div className="w-1/12 text-center font-semibold">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('status')}</Title>
                                </div>
                                <div className="w-4/12 font-semibold">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('visibility')}</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('duration')}</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('start-date')}</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('end-date')}</Title>
                                </div>
                                <div className="w-3/12 font-semibold text-center">
                                    <Title isLoading={competitionsQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                </div>
                            </div>
                            {competitionsQuery.isLoading ? (
                                <Repeat count={perPage}>
                                    <div className="w-full flex p-4 gap-4">
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-16 h-5 flex-shrink-0"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-2/4 h-5"
                                        />
                                    </div>
                                </Repeat>
                            ) : (
                                competitions && competitions.map((compInfoObj) => (
                                    <div key={compInfoObj.guid} className="flex w-full p-2 items-center">
                                        <div className="w-1/12 text-center" title={compInfoObj.availability}>
                                            {compInfoObj.availability === "ACTIVE" ? (
                                                <FontAwesomeIcon color="green" icon={faCircle} />
                                            ) : (
                                                <FontAwesomeIcon color="red" icon={faCircle} />
                                            )}
                                        </div>
                                        <div className="w-4/12 px-2">{compInfoObj.name}</div>
                                        <div className="w-1/12 text-center" title={compInfoObj.visibility}>
                                            {compInfoObj.visibility === "PUBLIC" ? (
                                                <FontAwesomeIcon color="green" icon={faUnlock} />
                                            ) : (
                                                <FontAwesomeIcon color="blue" icon={faLock} />
                                            )}
                                        </div>
                                        <div className="w-1/12 px-2 text-center">
                                            {compInfoObj.duration 
                                            ? formatTime(compInfoObj.duration) 
                                            :<FontAwesomeIcon color="gray" icon={faInfinity} />}
                                        </div>
                                        <div className="w-1/12 text-center">
                                            {compInfoObj.startDate && formatDate(compInfoObj.startDate)}
                                        </div>
                                        <div className="w-1/12 text-center">
                                            {compInfoObj.endDate && formatDate(compInfoObj.endDate)}
                                        </div>
                                        <div className="w-3/12 text-center font-semibold">
                                            <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                <div>
                                                        <Button
                                                            color="green"
                                                            icon={faEye}
                                                            onClick={() => handleCompetitionViewSuccess(compInfoObj.guid)}
                                                        />
                                                </div>
                                                <div>
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() => handleCompetitionUpdate(compInfoObj.guid)}
                                                    />
                                                </div>
                                                <div>
                                                    <Button
                                                        color="red"
                                                        icon={faTrash}
                                                        onClick={() => handleCompetitionDelete({
                                                            guid: compInfoObj.guid,
                                                            name: compInfoObj.name,
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Container> 
                </>
            )}
            {!errorCompetitionsLoading && (
                !competitionsQuery.isLoading && competitionsQuery.data ? (
                    <div className="w-full flex justify-center">
                        <Pager pages={competitionsQuery.data.pages} page={competitionPage} setPage={setCompetitionPage} />
                    </div>
                ) : (
                    <div className="w-full flex justify-center p-4 gap-4">
                        <Repeat count={14}>
                            <Skeleton
                                circle
                                count={1}
                                baseColor="#e4e4e7"
                                highlightColor="#f4f4f5"
                                containerClassName="w-9"
                                className='h-9'
                            />
                        </Repeat>
                    </div>
                )
            )}
        </div>
    );

    const usersContent = () => (
        <div className="flex w-full flex-col gap-y-2">
            {errorUsersLoading ? (
                <div className="w-1/6 flex self-center">
                    <Button
                        color='green'
                        icon={faArrowsRotate}
                        onClick={handleUsersReload}
                    >
                        {i18next.t('reload')}
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full flex items-center">
                        <div className="w-1/12 flex items-center justify-center">
                            <Button
                                circle
                                isLoading={usersQuery.isLoading}
                                color="blue"
                                icon={faPlus}
                                title={i18next.t('create-new')}
                                onClick={handleUserCreate}
                            />
                        </div>
                        <div className="w-11/12">
                            <Banner color="green" borderColor='green'>Users</Banner>
                        </div>
                    </div>
                    <UserAdminSearch
                        isLoading={usersQuery.isLoading}
                        onSearch={handleUserSearch}
                        feedbackMessage={userFeedbackMessage}
                    />
                    <Container>
                        <div className="w-full divide-y">
                            <div className="w-full flex p-2 gap-2">
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={usersQuery.isLoading} size="lg">{i18next.t('status')}</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={usersQuery.isLoading} size="lg">{i18next.t('role')}</Title>
                                </div>
                                <div className="w-2/12 font-semibold">
                                    <Title isLoading={usersQuery.isLoading} size="lg">{i18next.t('email')}</Title>
                                </div>
                                <div className="w-2/12 font-semibold text-center">
                                    <Title isLoading={usersQuery.isLoading} size="lg">{i18next.t('username')}</Title>
                                </div>
                                <div className="w-2/12 font-semibold text-center px-2">
                                    <Title isLoading={usersQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={usersQuery.isLoading} size="lg">Linkedin</Title>
                                </div>
                                <div className="w-1/12 font-semibold text-center">
                                    <Title isLoading={usersQuery.isLoading} size="lg">Git Hub</Title>
                                </div>
                                
                                <div className="w-3/12 font-semibold text-center">
                                    <Title isLoading={usersQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                </div>
                            </div>
                            {usersQuery.isLoading ? (
                                <Repeat count={perPage}>
                                    <div className="w-full flex p-4 gap-4">
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-16 h-5 flex-shrink-0"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-2/4 h-5"
                                        />
                                    </div>
                                </Repeat>
                            ) : (
                                users && users.map((userInfoObj) => (
                                    <div key={userInfoObj.guid} className="flex w-full p-2 items-center">
                                        {(() => {
                                            switch (userInfoObj.status) {
                                                case "ACTIVE":
                                                    return (
                                                        <div className="w-1/12 text-center" title={i18next.t('active')}>
                                                            <FontAwesomeIcon color="green" icon={faCircle} />
                                                        </div>
                                                    );
                                                case "INACTIVE":
                                                    return (
                                                        <div className="w-1/12 text-center" title={i18next.t('inactive')}>
                                                            <FontAwesomeIcon color="orange" icon={faCircle} />
                                                        </div>
                                                    );
                                                case "DELETED":
                                                    return (
                                                        <div className="w-1/12 text-center" title={i18next.t('deleted')}>
                                                            <FontAwesomeIcon color="red" icon={faCircle} />
                                                        </div>
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })()}
                                        <div title={userInfoObj.roles[0] || "NONE"} className="w-1/12 text-center truncate">
                                            {userInfoObj.banned !== null ? (
                                                <span>
                                                    <s>{userInfoObj.roles[0] ? userInfoObj.roles[0].toUpperCase() : "NONE"}</s>
                                                </span>
                                            ) : (
                                                <span>{userInfoObj.roles[0] ? userInfoObj.roles[0].toUpperCase() : "NONE"}</span>
                                            )}
                                        </div>
                                        <div title={userInfoObj.email} className="w-2/12 truncate">
                                            {userInfoObj.banned !== null ? (
                                                <span>
                                                    <s>{userInfoObj.email}</s>
                                                </span>
                                            ) : (
                                                <span>{userInfoObj.email}</span>
                                            )}
                                        </div>
                                        <div className="w-2/12 px-2 text-center">
                                            {userInfoObj.banned !== null ? (
                                                <span>
                                                    <s>{userInfoObj.username}</s>
                                                </span>
                                            ) : (
                                                <span>{userInfoObj.username}</span>
                                            )}
                                        </div>
                                        <div className="w-2/12 px-2 text-center">
                                            {userInfoObj.banned !== null ? (
                                                <span>
                                                    <s>{userInfoObj.firstName ? userInfoObj.firstName : ''} {userInfoObj.lastName ? userInfoObj.lastName : ''}</s>
                                                </span>
                                            ) : (
                                                <span>{userInfoObj.firstName ? userInfoObj.firstName : ''} {userInfoObj.lastName ? userInfoObj.lastName : ''}</span>
                                            )}
                                        </div>
                                        <div className="w-1/12 text-center">
                                            {userInfoObj.linkedin && userInfoObj.linkedin !== false && (
                                                <div title={i18next.t('linkedin')}> 
                                                    <Link color="blue" to={userInfoObj?.linkedin}>
                                                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                                                        <span> {i18next.t('linkedin')}</span>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-1/12 text-center">
                                            {userInfoObj.github && userInfoObj.github !== false && (
                                                <div title={i18next.t('github')}> 
                                                    <Link color="black" to={userInfoObj?.github}>
                                                        <FontAwesomeIcon icon={faGithub} size="lg" />
                                                        <span> {i18next.t('github')}</span>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-3/12 text-center font-semibold">
                                            <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                <div>
                                                    <Button
                                                        color="green"
                                                        icon={faEye}
                                                        onClick={() => handleUserView(userInfoObj)}
                                                    />
                                                </div>
                                                <div>
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() => handleUserUpdate(userInfoObj)}
                                                    />
                                                </div>
                                                <div>
                                                    {userInfoObj.status === "DELETED" ? (
                                                        <Button
                                                            color="green"
                                                            icon={faUserCheck}
                                                            onClick={() => handleUserState(userInfoObj.guid, userInfoObj.email, userInfoObj.status)}
                                                            title={i18next.t('activate')}
                                                        />
                                                    ) : (
                                                        <Button
                                                            color="red"
                                                            icon={faUserLock}
                                                            onClick={() => handleUserState(userInfoObj.guid, userInfoObj.email, userInfoObj.status)}
                                                            title={i18next.t('delete')}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {userInfoObj.banned === null ? (
                                                        <Button
                                                        color="orange"
                                                        icon={faBan}
                                                        title={i18next.t('ban')}
                                                        onClick={() => handleUserBan(userInfoObj.guid)}
                                                    />
                                                    ) : (
                                                        <Button
                                                            color="blue"
                                                            icon={faBan}
                                                            title={i18next.t('ban-details')}
                                                            onClick={() => 
                                                                handleUserBanDetail(userInfoObj.username, userInfoObj.firstName, userInfoObj.lastName,
                                                                    userInfoObj.email, userInfoObj.banned?.reason, userInfoObj.banned?.startDate,
                                                                        userInfoObj.banned?.endDate, userInfoObj.banned?.comment
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Container>
                </>
            )}
            {!errorUsersLoading && (
                !usersQuery.isLoading && usersQuery.data ? (
                    <div className="w-full flex justify-center">
                        <Pager pages={usersQuery.data.pages} page={userPage} setPage={setUserPage} />
                    </div>
                ) : (
                    <div className="w-full flex justify-center p-4 gap-4">
                        <Repeat count={14}>
                            <Skeleton
                                circle
                                count={1}
                                baseColor="#e4e4e7"
                                highlightColor="#f4f4f5"
                                containerClassName="w-9"
                                className='h-9'
                            />
                        </Repeat>
                    </div>
                )
            )}
        </div>
    );

    return (
        <NavbarTemplate>
            <div className="flex flex-row w-full sm:px-20">
                <div className="w-2/12 flex flex-col gap-7 pr-10">
                    <div className="w-full flex flex-col gap-3">
                        <Button
                            icon={faList}
                            color={classNames(indexPage === 0 ? 'green' : 'white')}
                            onClick={() => changePage(0)}
                        >
                            {i18next.t('categories')}
                        </Button>
                        <Button
                            icon={faRankingStar}
                            color={classNames(indexPage === 1 ? 'green' : 'white')}
                            onClick={() => changePage(1)}
                        >
                            {i18next.t('competitions')}
                        </Button>
                        <Button
                            icon={faLayerGroup}
                            color={classNames(indexPage === 2 ? 'green' : 'white')}
                            onClick={() => changePage(2)}
                        >
                            {i18next.t('challenges')}
                        </Button>
                        <Button
                            icon={faUsers}
                            color={classNames(indexPage === 3 ? 'green' : 'white')}
                            onClick={() => changePage(3)}
                        >
                            {i18next.t('users')}
                        </Button>
                        <Button
                            icon={faUsersGear}
                            color={classNames(indexPage === 4 ? 'green' : 'white')}
                            onClick={() => changePage(4)}
                        >
                            Skills
                        </Button>
                        <Button
                            icon={faGears}
                            color={classNames(indexPage === 5 ? 'green' : 'white')}
                            onClick={() => changePage(5)}
                        >
                            Domains
                        </Button>
                    </div>
                </div>
                <div className="w-10/12 flex flex-col gap-y-2"> 
                    <div className="w-full flex-row gap-y-2">
                        {feedbackElement}
                    </div>
                    <div className="w-full">
                        {indexPage === 0 && categoriesContent()}
                        {indexPage === 1 && competitionsContent()}
                        {indexPage === 2 && challengesContent()}
                        {indexPage === 3 && usersContent()}
                        {indexPage === 4 && skillsContent()}
                        {indexPage === 5 && <ControlPanelDomain />}
                    </div>
                </div>
            </div>
        </NavbarTemplate>
    );
}

export default ControlPanel;
