/**
 * banAdminUser banea a un usuario ilimitadamente o por un tiempo determinado.
 * @method POST
 * @param {string} guid
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function banAdminUser(guid, reason, endDate, comment, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/users/ban`;
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'User-Agent': window.navigator.userAgent,
            Authorization: jwt,
        };
        
        const body = JSON.stringify({ guid, reason, endDate, comment });

        const response = await fetch(url, { method, headers, body });

        if (!response.ok) {
            const errorData = { controlled: true, data: await response.json() };
            throw errorData;
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}