import { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { faArrowsRotate, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import useSession from '../../hooks/useSession';
import useFeedback from '../../hooks/useFeedback';
import useModal from '../../hooks/useModal';
import Button from '../atoms/Button';
import Banner from '../atoms/Banner';
import Feedback from '../atoms/Feedback';
import Container from '../atoms/Container';
import Title from '../atoms/Title';
import SimpleSearch from '../molecules/SimpleSearch';
import Repeat from '../atoms/Repeat';
import Pager from '../molecules/Pager';
import createAdminDomain from '../../services/createAdminDomain';
import DomainCreateModal from '../modals/DomainCreateModal';
import updateAdminDomain from '../../services/updateAdminDomain';
import DomainUpdateModal from '../modals/DomainUpdateModal';
import getAdminDomainsPaginated from '../../services/getAdminDomainsPaginated';

/**
 * ControlPanelDomain organism creates the logic for the domain component in the control panel
 */
function ControlPanelDomain() {

    
    const { session } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    
    const perPage  = 10;
    
    const nameRef = useRef();
    const domainRef = useRef();

    const [domainPage, setDomainPage] = useState(0);

    const [domains, setDomains] = useState();
    const [searchDomainTerm, setSearchDomainTerm] = useState('');
    const [errorDomainsLoading, setErrorDomainsLoading] = useState(false);

    const queryClient = useQueryClient();

    // Reset domain page when filters change
    useEffect(() => {
        setDomainPage(0);
    }, [searchDomainTerm]);

    const queryKeyDomain  = [session, 'admin', 'domains', domainPage, perPage, searchDomainTerm];

    const domainQuery = useQuery({
        queryKey: queryKeyDomain,
        queryFn: () => getAdminDomainsPaginated(domainPage, perPage, session, i18next.language, searchDomainTerm),
        enabled: !!session,
        retry: (failureCount, error) => {
            if (error.response?.status === 503 || error.message === "Network Error") {
                return failureCount < 3;
            }
            return false;
        },
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback("error", error.messages.join(', '));
            setErrorDomainsLoading(true);
        },
        onSuccess: () => {
            setErrorDomainsLoading(false);
        },
        onSettled: () => {
            removeFeedback();
        }
    });

    const handleDomainReload = () => {
        setErrorDomainsLoading(false);
        domainQuery.refetch();
    };

    const handleDomainSearch = (name) => {
        setSearchDomainTerm(name);
    };

    useEffect(() => {
        if (domainQuery.isSuccess && domainQuery.data) {
            setDomains(domainQuery.data.domains);
        }
    }, [domainQuery.isSuccess, domainQuery.data]);

    const domainCreateMutation = useMutation({
        mutationFn: (domain) =>
            createAdminDomain(
                domain.name,
                domain.domain,
                domain.settings,
                session,
                i18next.language
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: async () => {

            const totalPages = domainQuery.data?.pages || 1;

            const totalElements = domainQuery.data?.domains.length || 1;

            (totalElements % perPage === 0) ? setDomainPage(totalPages) : setDomainPage(totalPages - 1);

            queryClient.invalidateQueries(queryKeyDomain);
            
            createFeedback('success', i18next.t('domain-created-successfully'));
        }
    });

    /**
     * Create a new Domain
     */
    const handleDomainCreate = () => {

        const onAccept = (selectedSettings) => {
            
            if (!nameRef.current.value) {
                return;
            }

            const trimmedName = nameRef.current.value.trim();
            const trimmedDomain = domainRef.current.value.trim() || null;

            domainCreateMutation.mutate({
                name: trimmedName,
                domain: trimmedDomain,
                settings: selectedSettings,
            });

            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <DomainCreateModal
                nameRef={nameRef}
                domainRef={domainRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />, true
        );
    };

    const domainUpdateMutation = useMutation({
        mutationFn: ({guid, name, domain, settings}) => 
            updateAdminDomain(guid, name, domain, settings, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('domain-updated-successfully'));
            queryClient.invalidateQueries(queryKeyDomain);
        }
    });

    /**
     * Update Domain
     */
    const handleDomainUpdate = (domainInfoObj) => {
        const onAccept = (selectedSettings) => {
            if (!nameRef.current.value) {    
                return;
            }

            const trimmedName = nameRef.current.value.trim();
            const trimmedDomain = domainRef?.current?.value.trim() || null;

            domainUpdateMutation.mutate({
                guid: domainInfoObj.guid?.toString(),
                name: trimmedName,
                domain: trimmedDomain,
                settings: selectedSettings
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <DomainUpdateModal
                nameRef={nameRef}
                domainRef={domainRef}
                onAccept={onAccept}
                onCancel={onCancel}
                domainInfoObj={{
                    guid: domainInfoObj.guid,
                    name: domainInfoObj && domainInfoObj.name ? domainInfoObj.name : '',
                    domain: domainInfoObj && domainInfoObj.domain ? domainInfoObj.domain : '',
                    settings: domainInfoObj && domainInfoObj.setting ? domainInfoObj.setting : [],
                }}
            />, true
        );
    };

    const { isFetching } = domainQuery;

    return (
        <div className="flex w-full flex-col gap-y-2">
            {!isFetching && errorDomainsLoading && (
                <div className="w-1/6 flex self-center">
                    <Button
                        color="green"
                        icon={faArrowsRotate}
                        onClick={handleDomainReload}
                    >
                        {i18next.t('reload')}
                    </Button>
                </div>
            )}
    
            {!errorDomainsLoading && (
                <>
                    <div className="w-full flex items-center">
                        <div className="w-1/12 flex items-center justify-center">
                            <Button
                                circle
                                isLoading={isFetching}
                                color="blue"
                                icon={faPlus}
                                title={i18next.t('create-new')}
                                onClick={handleDomainCreate}
                            />
                        </div>
                        <div className="w-11/12">
                            <Banner color="green" borderColor="green">Domains</Banner>
                        </div>
                    </div>
                    <SimpleSearch 
                        isLoading={isFetching}
                        placeholder={i18next.t('search-domains')}
                        onSearch={handleDomainSearch} 
                    />
    
                    {searchDomainTerm && (
                        <Feedback type={domains?.length > 0 ? "success" : "warn"}>
                            {domains?.length > 0 
                                ? `${i18next.t('domains-found')} “${searchDomainTerm}”`
                                : `${i18next.t('no-domains-found')}`}
                        </Feedback>
                    )}
    
                    <Container>
                        <div className="w-full flex-row gap-y-2">
                            {feedbackElement}
                        </div>
                        <div className="w-full divide-y">
                            <div className="w-full flex p-2 gap-2">
                                <div className="w-2/12 font-semibold">
                                    <Title size="lg">{i18next.t('name')}</Title>
                                </div>
                                <div className="w-3/12 font-semibold">
                                    <Title size="lg">{i18next.t('domains')}</Title>
                                </div>
                                <div className="w-5/12 font-semibold">
                                    <Title size="lg">{i18next.t('settings')}</Title>
                                </div>
                                <div className="w-2/12 font-semibold">
                                    <Title size="lg">{i18next.t('actions')}</Title>
                                </div>
                            </div>
    
                            {isFetching && (
                                <Repeat count={perPage}>
                                    <div className="w-full flex p-4 gap-4">
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-16 h-5 flex-shrink-0"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-1/4 h-5"
                                        />
                                        <Skeleton
                                            baseColor="#e4e4e7"
                                            highlightColor="#f4f4f5"
                                            height="100%"
                                            containerClassName="w-2/4 h-5"
                                        />
                                    </div>
                                </Repeat>
                            )}
                            
                            {!isFetching && domains && domains.length > 0 && (
                                domains.map((domainInfoObj) => (
                                    <div key={domainInfoObj.guid} className="flex w-full p-2 items-center">
                                        <div className="w-2/12">{domainInfoObj.name}</div>
                                        <div className="w-3/12">{domainInfoObj.domain}</div>
                                        <div className="w-5/12">
                                            {domainInfoObj.setting && domainInfoObj.setting.length > 0
                                                ? domainInfoObj.setting.join(', ')
                                                : i18next.t('no-settings')}
                                        </div>
                                        <div className="w-2/12 font-semibold">
                                            <div className="flex w-full flex-wrap gap-y-2">
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() => handleDomainUpdate(domainInfoObj)}
                                                    >
                                                        {i18next.t('edit')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Container>
                </>
            )}
            {!errorDomainsLoading  && (
                <div className="w-full flex justify-center">
                    <Pager isLoading={isFetching} pages={domainQuery.data?.pages || 1} page={domainPage} setPage={setDomainPage} />
                </div>
            )}
        </div>
    );
}

export default ControlPanelDomain;