// DomainUpdateModal.jsx
import { useState, useEffect } from 'react';
import { faBan, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import SelectMultiple from '../atoms/SelectMultiple';

/**
 * DomainUpdateModal allows the user to update a domain
 * @param {{
 *      nameRef: React.RefObject<HTMLInputElement>;
 *      domainRef: React.RefObject<HTMLInputElement>;
 *      domainInfoObj: {
 *          guid: string;
 *          name: string;
 *          domain: string;
 *          settings: string[];
 *      };
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function DomainUpdateModal({ 
    nameRef,
    domainRef,
    domainInfoObj,
    onAccept, 
    onCancel 
}) { 

    const availableSettings = [
        "NOCOPY",
        "NOPASTE",
        "NOSELECTION",
        "FORCECAM"
    ];

    const [selectedSettings, setSelectedSettings] = useState([]);

    useEffect(() => {
        if (domainInfoObj?.settings) {
            setSelectedSettings(domainInfoObj.settings);
        }
    }, [domainInfoObj]);

    const settingsObject = {
        '': availableSettings.reduce((acc, setting) => {
            acc[setting] = setting;
            return acc;
        }, {})
    };

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const validate = () => {
        const validationErrors = [];

        if (!nameRef.current.value) {
            validationErrors.push(i18next.t('domain-name'));
        }

        if (!domainRef.current.value) {
            validationErrors.push(i18next.t('domain-value'));
        }

        if (nameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        if (domainRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-domain'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            createFeedback('warn', errorMessage);
            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept(selectedSettings);
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
                <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('domain-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('name')}</Title>
                <Input
                    inputRef={nameRef}
                    color="gray"
                    type="text"
                    defaultValue={domainInfoObj.name}
                    placeholder={i18next.t('domain-ex-name')}
                    focus
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('domain-url')}</Title>
                <Input
                    inputRef={domainRef}
                    color="gray"
                    type="text"
                    defaultValue={domainInfoObj.domain}
                    placeholder={i18next.t('domain-ex-url')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('settings')}</Title>
                <SelectMultiple
                    color="gray"
                    selected={selectedSettings}
                    onSelect={(selected) => setSelectedSettings(selected)}
                    options={settingsObject}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    {i18next.t('update')}
                </Button>
            </div>
        </div>
    );
}

DomainUpdateModal.propTypes = {
    nameRef: PropTypes.shape().isRequired,
    domainRef: PropTypes.shape().isRequired,
    domainInfoObj: PropTypes.shape({
        guid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired,
        settings: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DomainUpdateModal;