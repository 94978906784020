/**
 * getAdminDomainsPaginated gets all the domains paginated
 * @method POST
 * @param {number} page
 * @param {number} perPage
 * @param {string} jwt
 * @param {string} language
 * @param {string} [name] - Optional search parameter to filter domains by name
 * @returns {Promise<{
*      domains:{
*          guid: string;
*          name: string;
*          domain: string;
*          setting: string[];
*      }[];
*      pages: number;
* }>}}
*/
export default async function getAdminDomainsPaginated(page, perPage, jwt, language, name = '') {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/domain/list`;
       const method = 'POST';
       const body = { page, perPage, name };
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           Authorization: jwt,
       };

       const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}