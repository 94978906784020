/**
 * createAdminDomain creates a new domain.
 * @method POST
 * @param {string} name - Name of the domain
 * @param {string} domain - URL of the domain (can be null)
 * @param {Array<string>|null} settings - Settings that will be associated with the domain
 * @param {string} session - Session token for authentication
 * @param {string} language - Language of the request for localization purposes
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}}
 */
export default async function createAdminDomain(name, domain, settings, session, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/domain`;
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'User-Agent': window.navigator.userAgent,
            Authorization: session,
        };

        const body = JSON.stringify({
            name,
            domain,
            settings
        });

        const response = await fetch(url, { method, headers, body });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
